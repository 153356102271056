import Vue from "vue";
import Vuex from "vuex";

import apiClient from "@/plugins/apiClient.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loader: "none"
  },
  mutations: {
    SET_LOADER(state) {
      state.loader = "";
    },
    RESET_LOADER(state) {
      state.loader = "none";
    }
  },
  actions: {
    fetchResiduals(context) {
      apiClient
        .downloadResidual()
        .then(response => {
          context.commit("SET_LOADER");
          console.log("from state");
          console.log(response.data);
          context.commit("RESET_LOADER");
        })
        .catch(() => console.log("error"));
    }
  }
});
