<template>
  <div>
    <v-progress-circular
      v-bind:style="{ display: loader }"
      :size="50"
      color="primary"
      indeterminate
    ></v-progress-circular>
    <v-btn
      v-if="loader == 'none'"
      @click="downloadResidual"
      x-large
      color="success"
      dark
    >
      Magic!
    </v-btn>
  </div>
</template>

<script>
import apiClient from "@/plugins/apiClient.js";

export default {
  name: "HelloWorld",
  data() {
    return {
      loader: "none"
    };
  },
  methods: {
    async downloadResidual() {
      this.loader = "";
      this.$toasted.show(
        "Trying to login and initiate download! This may take upto 10 seconds",
        {
          theme: "bubble",
          position: "top-center",
          duration: 10000
        }
      );
      try {
        this.downloadCsvBlob("residual", await apiClient.getResidualCsv());
        this.downloadCsvBlob("merchant", await apiClient.getMerchantCsv());
        this.downloadCsvBlob(
          "special-even",
          await apiClient.getSpecialResidualsEvenCsv()
        );
        this.downloadCsvBlob(
          "special-odd",
          await apiClient.getSpecialResidualsOddCsv()
        );
      } catch (error) {
        this.$toasted.show(error.message);
        console.log("error");
      }
    },
    downloadCsvBlob(name, response) {
      try {
        this.loader = "";
        const currentTime = Date.now();
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));

        var fileLink = document.createElement("a");

        fileLink.href = fileURL;

        fileLink.setAttribute("download", `${name}-${currentTime}.csv`);

        document.body.appendChild(fileLink);

        fileLink.click();
        this.$toasted.show(`Downloaded ${name} Report !!`, {
          theme: "bubble",
          position: "top-center",
          duration: 5000
        });
        this.loader = "none";
      } catch (error) {
        this.loader = "none";
        this.$toasted.show(error, {
          theme: "bubble",
          position: "top-center",
          duration: 5000
        });
      }
    }
  }
};
</script>
