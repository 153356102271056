import axios from "axios";

const apiClient = axios.create({
  baseURL: "https://youtubevision.herokuapp.com/api/tsys",
  withCredentials: false,
  responseType: "blob"
});

export default {
  getLatestCookie() {
    return apiClient.get("/getLatestCookies");
  },
  getResidualCsv() {
    return apiClient.get("/downloadResidualCsv");
  },
  getMerchantCsv() {
    return apiClient.get("/downloadMerchantCsv");
  },
  getSpecialResidualsEvenCsv() {
    return apiClient.get("/downloadSpecialResidualsEvenCsv");
  },
  getSpecialResidualsOddCsv() {
    return apiClient.get("/downloadSpecialResidualsOddCsv");
  }
};
